import { Paper, Typography, Grid, CardMedia, Card, CardContent, Tooltip } from "@mui/material"
import DivietoFuocoIcon from "./icon/DivietoFuocoIcon"
import DivietoMuccaIcon from "./icon/DivietoMuccaIcon"
import DivietoRifiutoIcon from "./icon/DivietoRifiutiIcon"
import DivietoTendaIcon from "./icon/DivietoTendaIcon"
import DivietoCaccaIcon from "./icon/DivietoCaccaIcon"
import DivietoLightIcon from "./icon/DivietoLuciIcon"
import DivietoCaneIcon from "./icon/DivietoCaneIcon"
import React from "react"
import { useTranslation } from "react-i18next"

const HomeDescription = ({blocchi,settori,min,max,reffer}) => {
    const { t } = useTranslation()
    const ROOT_TRANSLATION = "homepage."
    const translate = (key) => t(`${ROOT_TRANSLATION}${key}`)
    
    const CustomCard = ({image, children}) => {
        return (
            <Card item xs={3} md={3} lg={2} component={Grid}>
                <CardMedia component='img' image={image} />
                <CardContent sx={{ p: 1 }} >
                    <Typography  fontWeight={550} align="center" >{ children }</Typography>
                </CardContent>
            </Card>
        )
    }

    const iconSx = { fontSize: { xs: 40, md: 48, lg: 64 } }

    const Divieto = ({title,children}) => (
        <Tooltip placement="top" title={translate(`divieto.${title}`)} arrow components={Grid} enterTouchDelay={0} leaveTouchDelay={5000} >
           <> { children } </>
        </Tooltip>
    )

    return (
        <Grid ref={reffer} container display={'flex'} justifyContent='center' sx={{ mt: {xs: 3, md: -2} }}>
            <Paper item elevation={3} 
                sx={{ p: { xs:2, md: 5}, zIndex: 1}}  
                xs={11} md={10} lg={8} component={Grid}>
                <Grid container gap={{ xs: 1, md:3 }} justifyContent="center" sx={{ pb: { xs:0, md: 1}}} >
                    <Divieto title="cane">
                        <DivietoCaneIcon sx={iconSx} />
                    </Divieto>
                    <Divieto title="cacca">
                        <DivietoCaccaIcon sx={iconSx} />
                    </Divieto>
                    <Divieto title="fuoco">
                        <DivietoFuocoIcon sx={iconSx} />
                    </Divieto>
                    <Divieto title="pascolo">
                        <DivietoMuccaIcon sx={iconSx} />
                    </Divieto>
                    <Divieto title="rifiuti">
                        <DivietoRifiutoIcon sx={iconSx} />
                    </Divieto>
                    <Divieto title="tenda">
                        <DivietoTendaIcon sx={iconSx} />
                    </Divieto>
                    <Divieto title="luci">
                        <DivietoLightIcon sx={iconSx} />
                    </Divieto>
                </Grid>
                <Typography fontSize={29} align="center" letterSpacing={4}>PIETRA DEL TORO</Typography>
                <Typography align="center" ><i>Campomaggiore boulder area</i></Typography>
                <Typography align="center" component={Grid}>
                    <p>{ translate("presentazione.1") }<br /><strong>Pietra Del Toro</strong>.</p>
                    <p>{ translate("presentazione.2") }<br />{ translate("presentazione.3") }</p>
                    { translate("presentazione.-4") } <strong>Pietra Del Toro{ translate("presentazione.4") }</strong>
                    <p>{ translate("presentazione.5") }</p>

                    <Typography color={'#008700'} fontWeight={600} component='label'>{translate("presentazione.6")}</Typography>
                    <Typography color={'#C80000'} fontWeight={600} component={Grid} lineHeight={2}>{translate("presentazione.13")}</Typography>
                    <Typography color={'#C80000'} fontWeight={600} component={Grid} lineHeight={2}>{translate("presentazione.7")}</Typography>
                    <Typography color={'#C80000'} fontWeight={600} component={Grid} lineHeight={2}>{translate("presentazione.8")}</Typography>
                    <Typography color={'#C80000'} fontWeight={600} component={Grid} lineHeight={2}>{translate("presentazione.9")}</Typography>
                    <Typography color={'#C80000'} fontWeight={600} component={Grid} lineHeight={2}>{translate("presentazione.10")}</Typography>
                    <Typography color={'#C80000'} fontWeight={600} component={Grid} lineHeight={2}>
                        {translate("presentazione.11,5")}
                         <Typography color={'#008700'} fontWeight={600} component='label'>{translate("presentazione.11")}</Typography>.<br />
                    </Typography>
                    <Typography color={'#C80000'} fontWeight={600} component={Grid} lineHeight={2}>{translate("presentazione.12")}</Typography>
                </Typography>
                
                <Grid container justifyContent='center' mt={5} gap={[1,8]}>
                    <CustomCard image={"Media/home/info1.webp"}>
                        { translate('desc.first') }
                    </CustomCard>
                    <CustomCard  image={"Media/home/info2.webp"}>
                        { translate('desc.second').format(blocchi,min,max) }
                    </CustomCard>
                    <CustomCard image={"Media/home/info3.webp"}>
                        { translate('desc.third').format(settori) }
                    </CustomCard>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default HomeDescription