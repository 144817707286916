import HomePage from "./pages/Home";
import { lazy } from "react";

export const logoLink = {
    url: '',
    component: HomePage,
    label: 'Pietra del Toro'
}

export const pageLink = [
    {
        url: '/boulder',
        component: lazy(() => import('./pages/Boulder')),
        label: 'boulder'
    },
    {
        url: '/gallery',
        component: lazy(() => import('./pages/Media')),
        label: 'galleria'
    },
    {
        url: '/#news',
        state: { clicked: true },
        component: HomePage,
        label: 'news'
    },
    {
        url: '/#who',
        state: { clicked: true },
        component: HomePage,
        label: 'contatti'
    },
    {
        url: '/#where',
        state: { clicked: true },
        component: HomePage,
        label: 'dove'
    }
]

export const hidePages = [
    {
        url: '/upload',
        component: lazy(() => import('./pages/Upload')),
    }
]

export const modalLink = [
    {
        url: '/login',
        component: lazy(() => import('./components/Login')),
        logo: '/Media/watermark.png'
    },
    {
        url: '/verify',
        component: lazy(() => import('./components/CompleteRegistration')),
        logo: false
    },
    {
        parent: '',
        parentHash: "#news", 
        url: '/event/:idParams',
        component: lazy(() => import('./components/InfoEvento')),
        logo: false
    },
    {
        url: '/profile/:idU',
        component: lazy(() => import('./components/UserProfile')),
        logo: true
    },
    {
        url: '/privacy',
        component: lazy(() => import('./pages/PP')),
        logo: false
    },
    {
        url: '/terms',
        component: lazy(() => import('./pages/Terms')),
        logo: false
    },
    {
        url: '/reset',
        component: lazy(() => import('./components/PasswordDialog')),
        logo: false
    }
]

export const getRoute = (url,routes) => {
    return (routes ?? [...pageLink, ...hidePages, ...modalLink]).find(page => {
        const pattern = page.url.split("/").map(s => s.startsWith(":") ? "\\w+" : s).join("\/")
        return new RegExp(`^${pattern}$`).test(url)
    })
}

export const isModal = (uri) => !!getRoute(uri, modalLink)