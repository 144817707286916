import { Card, CardContent, CardMedia, Container, Typography, Grid, Button, Paper, Stack, ButtonBase } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import useNavigation from "../hook/navigation"

export default ({ news, reffer }) => {
    const navigate = useNavigation()

    const { t } = useTranslation()
    const ROOT_TRANSLATION = "homepage.news."
    const translate = (key) => t(`${ROOT_TRANSLATION}${key}`)

    const iClicked = (id, type) => {
        switch(type){
            case 2:
                navigate(`/event/${id}`)
                break
            case 1:
                navigate(`boulder?s=${id}`)
                break
            case 0:
                navigate(`boulder?s=${id.split("_")[0]}&bc=${id.split("_")[1]}`)
                break
            case 3:
                navigate(`gallery?s=${id.split('_')[0]}&b=${id.split('_')[1]}&e=${id.split('_')[2]}`)
                break
        }
    }

    const renderLast = () => {
        const n = news.length > 3 ? news.slice(0,3) : news

        return n.map(i => (
            <Card key={i.id} item component={Grid} xs={11} md={3} sx={{ overflow: 'visible' }}>
                { i.newNews && (
                    <Grid sx={{ overflow: 'visible', height: 0, cursor: 'pointer' }} onClick={() => iClicked(i.idEvento, i.tipo)}>
                        <img src="media/home/eventiStandard/nuovo.webp" style={{ position: 'relative', top: '-8px', left:'-9px', zIndex: 1 }} />
                    </Grid>
                ) }
                <ButtonBase onClick={() => iClicked(i.idEvento, i.tipo)} sx={{ width: '100%'}}>
                    <CardMedia component='img' image={i.img} sx={{ height: 165, borderRadius: 1 }} />
                </ButtonBase>
                <CardContent>
                    <Typography fontWeight={600} align="center" >{ i.nome }</Typography>
                    <Container style={{ padding: '0px'}} sx={{ "> p": { height: 90, display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}} >
                        { React.createElement("p", {dangerouslySetInnerHTML: { __html:i.descrizione }}) }
                    </Container>
                    <Grid container justifyContent='space-between'>
                        <Grid item alignSelf={'center'} sx={{ opacity: 0.7 }}>
                            { new Date(i.dataInserimento).toLocaleDateString(undefined,{ day:'numeric', month: 'short', year:'numeric' }) }
                        </Grid>
                        <Grid item>
                            <Button variant={'contained'} onClick={() => iClicked(i.idEvento, i.tipo)}>Info</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        ))
    }

    const renderOlds = () => {
        const n = news.length > 3 ? news.slice(3) : []
        
        return n.map(i => (
            <Paper key={i.id} sx={{ cursor:'pointer', padding:1, borderBottom: '1px', display:'grid', gridTemplate:"'container'", '> *': { gridArea:'container'} }} element={Grid} 
                onClick={() => iClicked(i.idEvento, i.tipo)}>
                <Grid item>
                    <Typography fontWeight={600} >{ i.nome }</Typography>
                    <Grid >
                        { React.createElement("p", {dangerouslySetInnerHTML: { __html:i.descrizione }}) }
                    </Grid>
                </Grid>
                <Grid item sx={{ placeSelf:'start end', opacity: 0.7 }}>
                    { new Date(i.dataInserimento).toLocaleDateString(undefined,{ day:'numeric', month: 'short', year:'numeric' }) }
                </Grid>
            </Paper>
        ))
    }

    return (
        <Container ref={reffer} sx={{ bgcolor: 'primary.main', m: 0 }} style={{ position:'relative', marginTop: -45, padding: '100px 0px 50px 0px' }} 
            maxWidth={false} >
            <Container sx={{ color: 'white' }} >
                <Container id='i' style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                    <Typography fontSize={30} letterSpacing={4}>{ translate('title') }</Typography>
                    <Typography fontSize={15}><i>{ translate('subtitle') }</i></Typography>
                </Container>
                <Grid container gap={2} style={{ marginTop: 40 }} justifyContent='center'>
                    { renderLast() }
                </Grid>
                <Grid container justifyContent='center' >
                    <Paper elevation={2} item component={Grid} xs={11} sx={{ marginTop: 3, background: 'none', overflowY:'scroll' }} height={300} overflow={'hidden'}>
                        <Stack spacing={1} >
                            { renderOlds() }
                        </Stack>
                    </Paper>
                </Grid>
            </Container>
        </Container>
    )
}