import { useLocation, useNavigate } from "react-router-dom"
import { usePdtDispatch } from "../context/AppContext"

export default () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = usePdtDispatch()

    return (path, opt) => {
        dispatch({ type: 'previousLocation', location: location })
        navigate(path, { state: opt?.state ?? {}, replace: opt?.replace ?? false })
    } 
}