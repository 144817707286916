import { Box, Grid, Skeleton, Button } from "@mui/material"
import React, { useState } from "react"
import Slider from "react-slick"
import { useEffect } from "react"
import '../style/slider.css'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default (props) => {
    const initialTop = 47
    const ROOT_TRANSLATION = "homepage"

    const [top, setTop] = useState(initialTop)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const translate = (key) => t(`${ROOT_TRANSLATION}.${key}`) != `${ROOT_TRANSLATION}.${key}` ? t(`${ROOT_TRANSLATION}.${key}`) : key
    const onScroll = e => {
        setTop(initialTop + (e.target.documentElement.scrollTop/2))
    }

    useEffect(() => {
        window.addEventListener('scroll',onScroll)
        return () => window.removeEventListener('scroll',onScroll)
    },[])
    
    const handleLink = (link) => {
        if(link.startsWith("http")){
            const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }else{
            navigate(link)
        }
    }

    return (
        <Box ref={props.reffer} >
            <Slider style={{ top }} fade lazyLoad infinite speed={500} dots={false} slidesToShow={1}
                slidesToScroll={1} autoplay autoplaySpeed={3000} pauseOnHover arrows={false} centerPadding >
                { !!props.slides && props.slides.sort((a, b) => a.ordine - b.ordine).map((slide,i)=> (
                    <Grid key={i} >
                        <img style={{ width: "100vw", minWidth: "600px" }} src={`/media/home/${slide.img}`}/>
                        {
                            slide.link && 
                            <Button sx={{ position:'absolute', bottom: '10%', right: '4%', color: 'white' }} variant="contained" onClick={() => handleLink(slide.link)}>
                                { translate("carousel.btnGo") }
                            </Button>
                        }
                    </Grid>
                ))}
                { !props.slides && <Skeleton variant="rectangular" width='100%' height="45vw" /> }
            </Slider>
        </Box>
    )
}