import { Modal, Paper, Grid } from "@mui/material"
import React from "react"
import LogoIcon from "./icon/LogoIcon"

export default (props) => {
    const logoSizePx = props.logo ? 130 : 0;

    const modalStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const contentStyle = {
        position: 'fixed',
        maxWidth: '98vw',
        maxHeight: '95vh'
    }

    const whiteTopStyle = {
        position: "absolute",
        height: logoSizePx/2,
        width: '100%',
        backgroundColor: 'white',
        top: logoSizePx/2,
        zIndex: 1
    }

    const paperStyle = () => typeof props.logo === 'string' ? {
        paddingTop: logoSizePx ? logoSizePx/2 : 0,
        marginTop: logoSizePx? -logoSizePx/2 : 0,
        height: 'auto',
        transitionDuration: '570ms'
    } : {}

    const renderLogo = () => {
        return typeof props.logo === 'string' ? (
            <Grid item display={'flex'} justifyContent={'center'} >
                <LogoIcon sx={{ fontSize: logoSizePx, position: 'static', zIndex:3 }} />
            </Grid>
        ) : (<></>)
    }

    const renderWhiteTop = () => {
        return typeof props.logo === 'string' ? (
            <Paper elevation={0} style={whiteTopStyle} />
        ) : (<></>)
    }

    return (
        <Modal style={modalStyle} disableAutoFocus={true}
            open={props.open ?? true}
            onTouchStart={props.onTouchStart} onTouchMove={props.onTouchMove} onTouchEnd={props.onTouchEnd} 
            onClose={props.onClose}>
            <Grid style={contentStyle}>
                { renderLogo() }
                <Paper elevation={3} 
                    style={{ 
                        ...paperStyle(), 
                        ...(props.style ?? {} ), 
                        maxHeight: `calc( 95vh - ${logoSizePx}px)`,
                        overflowY: 'auto' }} >
                    { renderWhiteTop() }
                    { props.children }
                </Paper >
            </Grid>
        </Modal>
    )
}