import React, { useEffect } from "react";
import { createContext, useContext, useReducer } from "react";
import { getUserDetail } from "../service";

const initialState = { 
    snackbar: { open: false, text: undefined },
    userInfo: { admin: true },
    previousLocation: undefined
}

const reducer = (state, action) => {
    switch(action.type){
        case "snackbarOpen": {
            return { ...state, snackbar: { open: true, text: action.text }}
        }
        case "snackbarClose": {
            return { ...state, snackbar: { open: false, text: undefined }}
        }
        case "updateUser": {
            return { ...state, userInfo: action.user }
        }
        case "previousLocation": {
            return { ...state, previousLocation: action.location }
        }
    }
}

const Context = createContext(null)
const DispatchContext = createContext(null)

export const ContextProvider = ({ children }) => {
    const [context, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
            getUserDetail()
                .then(resp => dispatch({type: "updateUser", user: resp.data }))
                .catch(() => dispatch({type: "updateUser", user: undefined }))
    }, [])

    return (
        <Context.Provider value={context}>
            <DispatchContext.Provider value={dispatch}>
                { children }
            </DispatchContext.Provider>
        </Context.Provider>
    )
}

export const usePdtContext = () => {
    return useContext(Context)
}

export const usePdtDispatch = () => {
    return useContext(DispatchContext)
}