import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector'
import Flags from 'country-flag-icons/react/3x2'

const fallbackLng = ['it'];
export const availableLanguages = [ {
        lang: 'en',
        flag: Flags.US
    },
    {
        lang: 'it',
        flag: Flags.IT 
    }
];

i18n
  .use(Backend) 
  .use(LanguageDetector) 
  .use(initReactI18next) 
  .init({
    fallbackLng, 
    detection: {
      checkWhitelist: true, 
    },
    debug: false,
    whitelist: availableLanguages.map(el => el.lang),
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;