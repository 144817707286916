import React, { useEffect, useRef, useState } from "react"
import CustomCarousel from "../components/Carousel"
import HomeDescription from "../components/HomeDescription"
import News from "../components/News"
import Contacts from "../components/Contacts"
import { getHome } from "../service"
import { useLocation } from "react-router-dom"
import GMaps from "../components/GMaps"
import useNavigation from "../hook/navigation"

const HomePage = () => {
    const navigate = useNavigation()

    const [news, setNews] = useState([])
    const [slides, setSlides] = useState([])
    const [stats, setStats] = useState({blocchi:0,settori:0,gradoMin:0,gradoMax:0})

    const location = useLocation()
    const refLocation = useRef()
    refLocation.current = location

    const refTop = useRef()
    const refDesc = useRef()
    const refMap = useRef()
    const refNews = useRef()
    const refWho = useRef()
    
    const navigateOpt = { state: { scroll: true }, replace: true }
    useEffect(() => {
        const observer = new IntersectionObserver(e => {
            const el = e.find(observed => observed.isIntersecting)
            
            if(!el) return
            
            let ref = undefined
            switch(el.target){
                case refMap.current:
                    ref = { hash: "#where" }
                    break
                case refTop.current:
                    ref = { hash: "" }
                    break
                case refWho.current:
                    ref = { hash: "#who" }
                    break
                case refNews.current:
                    ref = { hash: "#news" }
                    break 
            }
            if(!!ref && refLocation.current.hash != ref.hash){ 
                navigate(ref,  navigateOpt) 
            }
        }, { threshold: [ 0.51 ] })
        
        observer.observe(refTop.current)
        observer.observe(refMap.current)
        observer.observe(refWho.current)
        observer.observe(refNews.current)
        
        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        if(!location.state?.scroll){
            let ref
            switch(location.hash){
                case '#where':
                    ref = refMap
                    break
                case '#who':
                    ref = refWho
                    break
                case '#news':
                    ref = refNews
                    break
                case '':
                    ref = refTop
                    break
            }
            ref.current.scrollIntoView(true)
        }
    }, [ location ])

    useEffect(() => {
        getHome().then(resp => {
            setNews(resp.news)
            setSlides(resp.slides)
            setStats(resp.stats)
        })
    }, [])

    return (
        <>
            <CustomCarousel reffer={refTop} slides={slides} />
            <HomeDescription reffer={refDesc} blocchi={stats.blocchi} settori={stats.settori} min={stats.gradoMin} max={stats.gradoMax} />
            <News reffer={refNews} news={news} />
            <Contacts ref={refWho} />
            <GMaps reffer={refMap} />
        </>
    )
}

export default HomePage