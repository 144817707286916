import { Grid, Typography } from "@mui/material"
import InstagramIcon from '@mui/icons-material/Instagram';
import React from "react"
import { useTranslation } from "react-i18next";
import useNavigation from "../hook/navigation"

const FootBar = () => {
    const navigate = useNavigation()
    const { t } = useTranslation()
    const ROOT_TRANSLATION = "footbar."
    const translate = (key) => t(`${ROOT_TRANSLATION}${key}`)

    const textColor = "gray"
    const Anchor = (props) => <a {...props} target="_blank" style={{ display:'flex', textDecorationLine:"none", color: props.color}}>{ props.children }</a>

    const CustomInsta = (props) => <Typography fontSize={{ xs: "1.25rem", md: "1.5rem" }} lineHeight="1" fontWeight={400} ><Anchor href={props.href} color={textColor}>{ props.children }</Anchor></Typography> 
    const CustomLinks = (props) => <Typography fontSize="smaller" sx={{ cursor: 'pointer' }} onClick={props.onClick} color={textColor}>{ props.children} </Typography> 

    return (
        <Grid container paddingY={5} sx={{ mt: 'auto' }}>
            <Grid container item justifyContent='center' gap={{ xs: 3, md: 8 }} >
                <Grid item>
                    <CustomInsta  href="https://www.instagram.com/pietradeltoro/">
                        <InstagramIcon  sx={{ color: textColor}} /> PietraDelToro
                    </CustomInsta>
                </Grid>
                <Grid item>
                    <CustomInsta href="https://www.instagram.com/explore/tags/pietradeltoro/" >#pietradeltoro</CustomInsta>
                </Grid>
            </Grid>
            <Grid container justifyContent='center' paddingTop={5} gap={5}>
                <Grid item >
                    <CustomLinks onClick={() => navigate('/privacy')}>{ translate('privacy')}</CustomLinks>
                </Grid>
                <Grid item >
                    <CustomLinks onClick={() => navigate('/terms')}>{ translate('termini')}</CustomLinks>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FootBar