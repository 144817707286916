import { Box, Card, CardContent, CardMedia, Container, Grid, Tab, Tabs, Typography } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next";

export default (props) => {
    const [tab, setTab] = useState(1)
    const { t } = useTranslation()
    const ROOT_TRANSLATION = "homepage.where."
    const translate = (key) => t(`${ROOT_TRANSLATION}${key}`)

    return (
        <Container ref={props.reffer} sx={{ color: 'white', bgcolor: 'primary.main', paddingTop:5, paddingX:"0!important" }} maxWidth={false} >
            <Container id='i' style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <Typography fontSize={30} letterSpacing={4}>
                    { translate('title') }
                </Typography>
                <Typography fontSize={15}><i>{ translate('sub') }</i></Typography>
            </Container>
            <Tabs value={tab} onChange={(_,v) => setTab(v)} centered
                sx={{ display: 'flex' }}
                textColor="inherit" TabIndicatorProps={{ sx: { backgroundColor: t => t.palette.primary.contrastText } }}>
                    <Tab label={translate('park')} />
                    <Tab label={translate('overview')} />
                    <Tab label={translate('main')} />
            </Tabs>
            <Box hidden={tab !== 0}> 
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12126.258646124354!2d16.083285914202865!3d40.55120617315332!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdcf577cf05ec7920!2sPietra%20del%20Toro%20-%20Parcheggio!5e0!3m2!1sit!2sit!4v1658475944578!5m2!1sit!2sit" 
                    width="100%" height="600" style={{ border:0 }} 
                    allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </Box>
            <Box hidden={tab !== 1}>
                <iframe 
                    width="100%" height="600" style={{ border:0 }} 
                    allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/d/embed?mid=1sl3cSND37oPxf-nn0wNKyPysyKbD8qE&ehbc=2E312F&noprof=1">
                </iframe>
            </Box>
            <Grid container minHeight={604} display={tab !== 2 ? "none" : "flex"} paddingY={1} paddingX={2} alignContent="center" justifyContent="center" flexDirection="column" >
                <Grid item  xs={11} display='flex' flexDirection="column" gap={1} width={{ xs: '100%', md: '840px'}} >
                    <Card component={Grid} sx={{ display: 'flex', width: { xs:'100%', md: '500px' }}}  alignSelf="start">
                        <CardMedia component="img" sx={{ width: 160 }}
                            image="Media/avvicinamento/20190609_102902.webp"/>
                        <Typography fontSize={14} textAlign="center" component={CardContent} sx={{ display:'flex', flexDirection:'column', justifyContent:'center' }} >
                            { translate('path1') }
                        </Typography>
                    </Card>
                    <Card component={Grid} sx={{ display: 'flex', width: { xs:'100%', md: '500px' }}} alignSelf="end">
                        <Typography fontSize={14} textAlign="center" component={CardContent} sx={{ display:'flex', flexDirection:'column', justifyContent:'center' }} >
                            { translate('path2') }
                        </Typography>
                        <CardMedia component="img" sx={{ width: 160 }}
                            image="Media/avvicinamento/20190609_103510.webp"/>
                    </Card>
                    <Card component={Grid} sx={{ display: 'flex', width: { xs:'100%', md: '500px' }}}  alignSelf="start">
                        <CardMedia component="img" sx={{ width: 160 }}
                            image="Media/avvicinamento/20190609_104125.webp"/>
                        <Typography fontSize={14} textAlign="center" component={CardContent} sx={{ display:'flex', flexDirection:'column', justifyContent:'center' }} >
                            { translate('path3') }
                        </Typography>
                    </Card>
                    <Card component={Grid} sx={{ display: 'flex', width: { xs:'100%', md: '500px' }}}  alignSelf="end">
                        <Typography fontSize={14} textAlign="center" component={CardContent} sx={{ display:'flex', flexDirection:'column', justifyContent:'center' }} >
                            { translate('path4') }
                        </Typography>
                        <CardMedia component="img" sx={{ width: 160 }}
                            image="Media/avvicinamento/20190609_104504.webp"/>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}