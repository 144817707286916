import React, { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import FootBar from './components/FootBar';
import NavBar from './components/NavBar';
import { logoLink, pageLink, modalLink, hidePages, isModal, getRoute } from './router';
import HomePage from "./pages/Home";
import Modal from "./components/CustomModal";
import CustomSnackbar from "./components/CustomSnackbar";
import { AxiosInterceptor } from "./service";
import { getUserDetail } from "./service";
import { usePdtDispatch, usePdtContext } from "./context/AppContext";
import useNavigation from "./hook/navigation";

export default (props) => {
    const dispatch = usePdtDispatch()
    const navigate = useNavigation()
    const { userInfo, previousLocation } = usePdtContext()
    const location = useLocation()
    const [ routes, setRoutes ] = useState([...pageLink, ...hidePages])

    const Manage = lazy(() => import('./pages/Manage'))

    useEffect(() => {
        setRoutes(userInfo ? [...pageLink, ...hidePages] : pageLink)
    }, [userInfo])

    const closeModal = () => {
        var locationOnClose = isModal(location.pathname) ? backLocation() : undefined
        if (locationOnClose){
            navigate(locationOnClose, { replace: true })
        }else{
            navigate(-1)
        }
    }

    const backLocation = () => previousLocation ?? ({
        pathname: getRoute(location.pathname).parent ?? "/",
        hash: getRoute(location.pathname).parentHash ?? "",
        key: "init"
    })

    const renderRoutes = () => (
        <>
            <Routes location={isModal(location.pathname) ? backLocation() : location} >
                <Route index element={<HomePage  {...props} />} />
                {
                    routes.map((page,i) => (
                        <Route key={i} path={page.url} element={
                            <Suspense>
                                <page.component {...props} />
                            </Suspense>} /> 
                    ))
                }
                { userInfo?.admin && (
                    <Route path="/manage/*" element={
                        <Suspense>
                            <Manage />
                        </Suspense>
                    } />
                )}
                <Route path="*" element={isModal(location.pathname) ? <HomePage {...props} /> : <Navigate to={'/'} replace />} />
            </Routes>
            <Routes>
                {
                    modalLink.map((page,i) => (
                        <Route key={i} path={page.url} element={
                            <Modal logo={page.logo} onClose={closeModal} >
                                <Suspense fallback={<></>}>
                                    <page.component onDone={closeModal} {...props}/>
                                </Suspense>
                            </Modal>
                        } /> 
                    ))
                }
            </Routes>
        </>
    )

    const handleInterceptor = (resp) => {
        if( (resp.headers && resp.headers['x-pdt-out']) || resp.status == 403 ){
            dispatch({type: "updateUser", user: undefined })
        }else if( resp.headers && resp.headers['x-pdt-in'] ){
            getUserDetail()
                .then(resp => dispatch({type: "updateUser", user: resp.data }))
                .catch(() => dispatch({type: "updateUser", user: undefined }))
        }
    }

    return (
        <AxiosInterceptor onError={handleInterceptor} onSuccess={handleInterceptor}>
            <CustomSnackbar />
            <NavBar routes={pageLink} logo={logoLink}/>
            { renderRoutes() }
            <FootBar />
        </AxiosInterceptor>
    )
}