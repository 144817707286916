import { SvgIcon } from "@mui/material";
import React from "react";

export default  (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 114 114" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(0.005887, 0, 0, -0.005887, -1328.00415, -10405.507812)" fill="#000000" stroke="none" style={{ transformOrigin: "1355.54px 10439.5px" }} >
            <path d="M6810 12785 c-118 -23 -203 -52 -309 -106 -506 -258 -718 -872 -476 -1385 142 -303 398 -511 727 -591 113 -28 385 -25 495 5 479 130 803 547 803 1035 0 290 -121 564 -339 769 -139 131 -313 224 -491 263 -105 23 -316 28 -410 10z"/>
            <path d="M6090 10369 c-283 -22 -524 -133 -725 -334 -116 -115 -120 -121 -680 -938 l-470 -686 -900 -3 -900 -3 -56 -23 c-169 -68 -274 -212 -286 -394 -18 -254 136 -464 371 -507 40 -7 385 -11 1065 -11 1102 0 1085 0 1206 60 33 16 80 49 105 73 36 35 316 424 751 1045 l44 64 6 -1994 c3 -1096 7 -2938 8 -4093 2 -2318 -3 -2173 65 -2310 89 -179 263 -292 477 -310 238 -20 482 112 581 315 73 149 68 -40 68 2630 l0 2400 115 0 115 0 0 -2379 c0 -2664 -5 -2482 71 -2640 170 -350 670 -442 958 -175 72 67 114 131 149 226 l27 73 5 4150 5 4150 103 3 102 3 0 -1488 c0 -1644 -3 -1562 65 -1699 86 -175 287 -277 486 -247 199 31 355 188 389 391 8 48 10 551 8 1787 l-4 1720 -22 96 c-65 285 -176 493 -358 675 -225 224 -497 346 -832 374 -141 12 -1961 11 -2112 -1z"/>
            <path d="M1545 6820 l-280 -280 283 -283 282 -282 282 282 283 283 -280 280 c-154 154 -282 280 -285 280 -3 0 -131 -126 -285 -280z"/>
            <path d="M2075 5750 l-280 -280 283 -283 282 -282 282 282 283 283 -280 280 c-154 154 -282 280 -285 280 -3 0 -131 -126 -285 -280z"/>
            <path d="M1720 4135 l0 -385 395 0 395 0 0 385 0 385 -395 0 -395 0 0 -385z"/>
            </g>
            <g transform="matrix(1.00885, 0, 0, 1.00885, -0.999976, -0.999976)" style={{ transformOrigin: "58px 58px" }} >
            <ellipse style={{ paintOrder: "fill", fillRule: "evenodd", fill: "none", stroke: "rgb(255, 0, 0)", strokeWidth: "10px" }} cx="58" cy="58" rx="50" ry="50"/>
            <path style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(255, 0, 0)", strokeWidth: "10px", transformBox: "fill-box", transformOrigin: "50% 50%" }} d="M 8.546 58.276 L 108.546 58.816" transform="matrix(0.707108, 0.707106, -0.707106, 0.707108, -0.000002, -0.000011)"/>
            <ellipse style={{ stroke: "rgb(0, 0, 0)", fill: "none", strokeWidth: "0.5px" }} cx="58" cy="58" rx="56.5" ry="56.5"/>
            </g>
        </SvgIcon>)
}