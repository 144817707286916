import { SvgIcon } from "@mui/material";
import React from "react";

export default  (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 114 114" xmlns="http://www.w3.org/2000/svg">
            <g style={{ transformOrigin: "22.7354px 66.2373px" }} transform="matrix(0.28, 0.484974, -0.484974, 0.28, 34.264595, -9.237319)">
            <path d="M 42.941 0 L 7.059 0 C 1.882 0 0 4.235 0 9.412 L 50 9.412 C 50 4.235 48.118 0 42.941 0 Z M 7.382 33.971 C 9.618 36.559 10.882 39.853 10.882 43.294 L 10.882 121.5 C 10.882 127.941 16.147 131.765 22.618 131.765 L 27.412 131.765 C 33.853 131.765 39.147 127.971 39.147 121.5 L 39.147 43.294 C 39.147 39.853 40.412 36.588 42.647 33.971 C 47.176 28.706 50 23.824 50 14.118 L 0 14.118 C 0 24.412 2.824 28.706 7.382 33.971 Z M 16.765 60.676 C 16.765 56.088 20.471 52.353 25 52.353 C 29.529 52.353 33.235 56.088 33.235 60.676 L 33.235 71.088 C 33.235 75.676 29.529 79.412 25 79.412 C 20.471 79.412 16.765 75.676 16.765 71.088 L 16.765 60.676 Z"/>
            <path d="M 19.118 70.882 C 19.118 75.411 24.02 78.241 27.941 75.977 C 29.761 74.926 30.882 72.984 30.882 70.882 C 30.882 66.354 25.98 63.524 22.059 65.788 C 20.239 66.839 19.118 68.781 19.118 70.882 Z"/>
            </g>
            <g transform="matrix(1.00885, 0, 0, 1.00885, -0.999976, -0.999976)" style={{ transformOrigin: "58px 58px" }}>
            <ellipse style={{ paintOrder: "fill", fillRule: "evenodd", fill: "none", stroke: "rgb(255, 0, 0)", strokeWidth: "10px" }} cx="58" cy="58" rx="50" ry="50"/>
            <path style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(255, 0, 0)", strokeWidth: "10px", transformBox: "fill-box", transformOrigin: "50% 50%" }} d="M 8.546 58.276 L 108.546 58.816" transform="matrix(0.707108, 0.707106, -0.707106, 0.707108, -0.000002, -0.000011)"/>
            <ellipse style={{ stroke: "rgb(0, 0, 0)", fill: "none", strokeWidth: "0.5px" }} cx="58" cy="58" rx="56.5" ry="56.5"/>
            </g>
        </SvgIcon>)
}