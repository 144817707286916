import axios from "axios"
import React, { useEffect } from "react"

export const ripEnum = {
    Flash: "flash", realizzato_in_giornata: "giornata", Lavorato: "lavorato"
}

export const abortController = () => new AbortController()

const axiosDefConfig = {
    baseURL: '/api'
}

export const http = axios.create(axiosDefConfig)

let intercept = http.interceptors.response.use(
    resp => resp.data,
    err => Promise.reject(err))


export const AxiosInterceptor = ({children, onSuccess, onError}) => {

    useEffect(() => {
        http.interceptors.response.eject(intercept)
        intercept = http.interceptors.response.use(
            resp => {
                onSuccess && onSuccess(resp)
                return resp.data
            },
            err => {
                onError && onError(err)
                return Promise.reject(err)
            }
    )}, [])

    return (
        <>
            {children}
        </>
    )
}

// Google
export const google = (credential, login, axiosConfig) => http.post(`account/google?login=${login}`, credential, axiosConfig)

// Login
export const login = (dto, axiosConfig) => {
    dto.mail = dto.mail.trim()
    return http.post('account/login',dto, axiosConfig)
}
// User Profile
export const getUser = (id, axiosConfig) => http.get(`account/get?id=${id}`, axiosConfig) 

// Register
export const register = (dto,type, axiosConfig) => http.post(`account/register?t=${type}`,dto, axiosConfig)

// Complete registration
export const verifyRegistration = (cod, axiosConfig) => http.get(`account/verify?cod=${cod}`, axiosConfig)

// Logout
export const logout = (axiosConfig) => http.get('account/logout', axiosConfig)

// User detail
export const getUserDetail = (axiosConfig) => axios.create(axiosDefConfig).get('account/account', axiosConfig)

// HomePage
export const getHome = (axiosConfig) => http.get('home/get', axiosConfig)

// Gallery All
export const getMedias = (filters, fav, page, axiosConfig) => http.post(`media/all?page=${page.number}&size=${page.size}&ordeerBy=${page.order}&ascending=${page.asc}&fav=${fav}`,{...filters, like: fav}, axiosConfig)

// Gallery One
export const getMedia = (id, axiosConfig) => http.get(`media/one?g=${id}`, axiosConfig)

// Gallery upload
export const uploadMedia = ({src, settore, blocco, evento, commento}, onUploadProgress) => new Promise(async (resolve, reject) => {
    const file = await (await fetch(src)).blob()
    const form = new FormData()
    form.append('file', file)
    if(settore) form.append('settore', settore)
    if(blocco) form.append('blocco', blocco)
    if(evento) form.append('evento', evento)
    if(commento) form.append('commento', commento)
    http.post(`media/upload`, form, {onUploadProgress}).then(resolve).catch(reject)
})

// Media filters
export const getMediaFilters = (axiosConfig) => http.get(`media/filters`, axiosConfig)

// Media count
export const countMedia = (filters, axiosConfig) => http.post(`media/count`, filters, axiosConfig)

// Boulders filters
export const getBoulderFilters = (axiosConfig) => http.get(`boulder/filters`, axiosConfig)

// Boulders list
export const getBoulders = (filters, favorite, done, axiosConfig) => http.post(`boulder/applyFilters`, {...filters, f: favorite, d: done}, axiosConfig)

// Boulder detail
export const getBoulder = (settore,blocco, axiosConfig) => http.get(`boulder/one?settore=${settore}&blocco=${blocco}`, axiosConfig)

// Preferiti get
export const getCommentoPreferiti = (blocco, axiosConfig) => http.get(`preferiti/get?blocco=${blocco}`, axiosConfig)
// Preferiti save
export const savePreferito = (blocco,commento, axiosConfig) => http.post(`preferiti/save`,{
    idBlocco: {
        id: blocco
    },
    commento: commento
}, axiosConfig)
//Preferiti getAll
export const allPreferiti = (axiosConfig) => http.get(`preferiti/all`, axiosConfig)
// Preferiti delete
export const deletePreferito = (blocco, axiosConfig) => http.delete(`preferiti/delete?blocco=${blocco}`, axiosConfig)

// Get realizzazione
export const getRealizzazione = (blocco, axiosConfig) => http.get(`realizzazioni/get?blocco=${blocco}`, axiosConfig)

// Get all by blocco realizzazione
export const getListRealizzazione = (blocco,start, axiosConfig) => http.get(`realizzazioni/getAll?blocco=${blocco}&page=${start}`, axiosConfig)

// Get all realizzazione
export const getListRealizzazioneLatest = (start, axiosConfig) => http.get(`realizzazioni/getLatest?page=${start ?? 0}`, axiosConfig)

// Count new news
export const getCountNewNews = (axiosConfig) => http.get(`news/areThere`, axiosConfig)

// Save realizzazione
export const saveRealizzazione = (blocco,voto,ripetizioni,commento, axiosConfig) => http.post(`realizzazioni/save`,{
    idB: {
        id: blocco
    },
    voto: voto,
    ripetizioni: ripetizioni,
    note: commento
}, axiosConfig)

// Realizzazione delete
export const deleteRealizzazione = (blocco, axiosConfig) => http.delete(`realizzazioni/delete?blocco=${blocco}`, axiosConfig)

// Get evento
export const getEvento = (idEvento, axiosConfig) => http.get(`evento/get?id=${idEvento}`, axiosConfig)

// Preiscrizione save
export const savePreiscrizione = (preisc, axiosConfig) => http.post(`preiscrizione/post`, preisc, axiosConfig)
// Preiscrizione read
export const getPreiscrizione = (evento, axiosConfig) => http.get(`preiscrizione/get?evento=${evento}`, axiosConfig)
// Preiscrizione delete
export const deletePreiscrizione = (evento, axiosConfig) => http.delete(`preiscrizione/delete?id=${evento}`, axiosConfig)
// Profilo blocchi
export const getBlocchiProfilo = (user, axiosConfig) => http.get(`realizzazioni/getFromUser?user=${user}`, axiosConfig)
// Aggiorna profilo
export const updateProfilo = (prof, axiosConfig) => http.post(`account/update`, prof, axiosConfig)
// Upload img acc
export const uploadImgAcc = (src, axiosConfig) => new Promise(async (resolve, reject) => {
    const file = await (await fetch(src)).blob()
    const form = new FormData()
    form.append('file', file)
    return http.post(`account/upload`, form, axiosConfig).then(resolve).catch(reject)
})
// Change password
export const changePassword = ({ psw, newPsw }, axiosConfig) => http.post(`account/changePassword`, { psw, newPsw }, axiosConfig)

// Reset request password
export const resetRequestPassword = ( email, axiosConfig ) => http.post(`account/requestResetPassword`, {email}, axiosConfig)
// Apply reset password
export const applyResetPassword = (cod,newPsw, axiosConfig) => http.post(`account/applyResetPassword`, {cod, newPsw}, axiosConfig)

// Search password
export const searchUser = (lookFor, axiosConfig) => http.get(`/account/search?lookFor=${lookFor}`, axiosConfig)

// Settori
export const getSettori = (axiosConfig) => http.get(`/settore/get`, axiosConfig)
// Settori delete
export const delSettore = (id, axiosConfig) => http.delete(`/settore/delete?id=${id}`, axiosConfig)
// Settori save
export const saveSettore = (settore, axiosConfig) => http.post(`/settore/post`, settore, axiosConfig)

// Blocco
export const getBlocchi = (axiosConfig) => http.get(`/boulder/get`, axiosConfig)
// Blocco delete
export const delBlocco = (id, axiosConfig) => http.delete(`/boulder/delete?id=${id}`, axiosConfig)
// Blocco save
export const saveBlocco = (blocco, axiosConfig) => http.post(`/boulder/post`, blocco, axiosConfig)

// News
export const getNews = (axiosConfig) => http.get(`/news/get`, axiosConfig)
export const saveNews = (news, axiosConfig) => http.post(`/news/post`, news, axiosConfig)
export const deleteNews = (id, axiosConfig) => http.delete(`/news/delete?id=${id}`, axiosConfig)

// Evento
export const getEventi = (axiosConfig) => http.get(`/evento/get`, axiosConfig)
export const saveEvento = (evento, axiosConfig) => new Promise(async (resolve, reject) => {
    const form = new FormData()
    Object.entries(evento).map(([k,v]) => form.append(k,v))
    if(evento.file) form.append('file', await (await fetch(evento.file)).blob())
    http.post(`evento/post`, form, axiosConfig).then(resolve).catch(reject)
})
export const deleteEvento = (id, axiosConfig) => http.delete(`/evento/delete?id=${id}`, axiosConfig)

// Preiscrizione
export const getAllPre = (evento, axiosConfig) => http.get(`/preiscrizione/getAll?evento=${evento}`, axiosConfig)

//Galleria
export const getMediaToApprove = (params,{signal}) => http.get(`/media/manage?${Object.entries(params).map(([k,v]) => `${k}=${v}`).join("&")}`, {signal})
export const updateMediaToApprove = (media, axiosConfig) => http.post(`media/update`, media, axiosConfig)
export const deleteMediaToApprove = (idMedia, axiosConfig) => http.delete(`media/delete?idGallery=${idMedia}`, axiosConfig)

// Slides
export const getAllSlides = (axiosConfig) => http.get(`slide/get`, axiosConfig)
export const saveSlide = (slide, axiosConfig) => new Promise(async (resolve, reject) => {
    const form = new FormData()
    Object.entries(slide).map(([k,v]) => form.append(k,v))
    if(slide.src) form.append('file', await (await fetch(slide.src)).blob())
    http.post(`slide/post`, form, axiosConfig).then(resolve).catch(reject)
})
export const deleteSlide = (id, axiosConfig) => http.delete(`slide/delete?id=${id}`, axiosConfig)

// Set realizzazione censura
export const setCensura = (dto, axiosConfig) => http.put(`realizzazioni/add`, dto, axiosConfig)
// remove realizzazione censura
export const removeCensura = ({idB,idU}, axiosConfig) => http.put(`realizzazioni/add`, {idB,idU}, axiosConfig)
// get one realizzazione censura
export const refreshRealizzazione = ({idB,idU}, axiosConfig) => http.put(`realizzazioni/one`, {idB,idU}, axiosConfig)

//media favorites
export const addMediaLike = (idG, axiosConfig) => http.get(`mediaPreferiti/add?media=${idG}`, axiosConfig)
export const removeMediaLike = (idG, axiosConfig) => http.delete(`mediaPreferiti/remove?media=${idG}`, axiosConfig)