import { SearchRounded } from "@mui/icons-material";
import { Avatar, Box, CircularProgress, Grid, List, ListItem, ListItemAvatar, ListItemButton,  ListItemText, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { searchUser } from "../service";
import useNavigation from "../hook/navigation"
import { useTranslation } from "react-i18next";

export default () => {
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigation()
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])

    useEffect(() => {
        let wait
        if(search.trim() != ""){
            setLoading(true)
            wait = setTimeout(() => {
                searchUser(search).then(setUsers).finally(() => setLoading(false))
            }, 750)
        }else{
            setLoading(false)
            setUsers([])
        }
        return () => clearTimeout(wait)
    }, [search])

    return (
    <Box display='flex' flexDirection='column' alignItems='center' p={1} gap={1}
        sx={{ width:{ xs:"95vw", md:"450px", maxHeight: `calc( 90vh - 130px)` }, boxSizing: 'border-box' }} >
        <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} >
            { loading && <CircularProgress size={24} sx={{ color: 'action.active', mr: 1, my: 0.5 }} />}
            { !loading && <SearchRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> }
            <TextField label={t('navbar.search')} variant="standard" fullWidth onChange={({currentTarget}) => setSearch(currentTarget.value)}/>
        </Box>
        <List  sx={{ overflowY: 'auto', maxHeight: 600, m: 1, width: "100%" }} >
            { users.map((el,i) => (
                <ListItem key={i} divider sx={{ padding:"8px 8px 8px 16px" }} slotProps={{ root: { top:0 } }}>
                    <ListItemButton onClick={() => navigate(`/profile/${el.id}`)}>
                        <ListItemAvatar>
                            <Avatar src={el.imgAcc?.startsWith('http') ? el.imgAcc : `/media/profilo/${el.imgAcc ?? 'imgprof.webp'}`} />
                        </ListItemAvatar>
                        <ListItemText disableTypography
                            primary={<Typography fontWeight={450}>{el.nome} {el.cognnome}</Typography>}
                            secondary={
                                <Grid container item paddingRight={8} flexDirection={'column'}>
                                    <Typography variant="caption">{el.sopranome}</Typography>
                                </Grid>
                            } />
                    </ListItemButton>
                </ListItem>
                ))
            }
        </List>
    </Box>
    )
}