import { SvgIcon } from "@mui/material";
import React from "react";

export default ({bgcolor, color, ...props}) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="123.68 387.25 138.51 133.22" {...props}>
            <polygon fill={bgcolor ?? "#EFA504"} points="239.71,387.25 155.38,388.57 144.65,404.88 123.68,436.76 140.25,513.96 242.97,520.47 &#10;&#9;&#9;&#9;262.19,447.87 &#9;&#9;" />
            <polygon fill={color ?? "#FFFFFF"} points="162.76,479.54 227.46,477.08 226.58,494.71 163.59,500.66 &#9;&#9;" />
            <polygon fill={color ?? "#FFFFFF"} points="160.56,421.4 230.11,424.07 227.46,475.98 162.76,478.45 &#9;&#9;" />
            <polygon fill={color ?? "#FFFFFF"} points="160.65,444.99 155.73,439.45 149.37,443.76 155.01,448.68 &#9;&#9;" />
            <polygon fill={color ?? "#FFFFFF"} points="230.06,444.07 235.81,439.45 244.84,445.2 237.45,448.68 &#9;&#9;" />
            <line fill="none" x1="162.76" y1="478.45" x2="227.46" y2="475.98" />
            <circle fill={bgcolor ?? "#EFA504"} cx="173.63" cy="490.19" r="5.18" />
            <circle fill={bgcolor ?? "#EFA504"} cx="217.04" cy="486.66" r="5.18" />
            <ellipse fill={bgcolor ?? "#EFA504"} cx="173.63" cy="451.33" rx="7.52" ry="9.2" />
            <path fill={color ?? "#FFFFFF"} d="M231.1,433.12l0.64-7c3.59,0.67,6.74-0.35,9.67-2.33c2.96-2,5.28-4.57,5.84-8.61 c0.32,0.8,0.39,1.52,0.46,2.25c0.53,6.15-1.08,11.34-5.98,15.07c-0.82,0.62-4.8,2.53-4.94,2.6c-0.39,0.2-2.96,1.14-3.77,1.29 c-0.05,0.22-1.56,0.25-2.2-0.02C231,435.3,231.07,434.21,231.1,433.12z"  />
            <path fill={color ?? "#FFFFFF"} d="M159.39,429.26l-0.1-7.02c-3.73,0.4-6.89-0.86-9.74-3.06c-2.88-2.22-5.05-4.96-5.31-9.03 c-0.39,0.77-0.52,1.49-0.65,2.21c-1.04,6.09,0.21,11.39,4.94,15.48c0.79,0.68,4.73,2.89,4.86,2.97c0.39,0.23,2.95,1.36,3.77,1.57 c0.04,0.22,1.58,0.37,2.26,0.15C159.32,431.44,159.34,430.35,159.39,429.26z" />
            <circle fill={color ?? "#FFFFFF"} cx="173.7" cy="452.22" r="2.08" />
            <circle fill={bgcolor ?? "#EFA504"} cx="217.04" cy="451.33" r="3.27" />
        </SvgIcon>
    )
}