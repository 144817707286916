import { AppBar, Avatar, Container, Drawer, IconButton, ListItemIcon , Menu, MenuItem, Tooltip, Typography, Badge, Tab, Tabs } from "@mui/material"
import { Login, Menu as MenuIcon } from '@mui/icons-material'
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { availableLanguages } from "../i18nConfig"
import { getCountNewNews, logout } from "../service"
import { usePdtContext, usePdtDispatch } from "../context/AppContext"
import useNavigation from "../hook/navigation"
import CustomModal from "./CustomModal"
import CercaUtente from "./CercaUtente"
import Preferiti from "./Preferiti"
import LogoIcon from "./icon/LogoIcon"

const Logo = (props) => {
    const navigate = useNavigation()

    return (
        <LogoIcon onClick={() => navigate(props.url)} sx={{ ...props.sx, fontSize: 48, cursor: "pointer" }} />
    )
}

const NavBar = (props) => {
    const navigate = useNavigation()
    const location = useLocation()

    const { t, i18n } = useTranslation()
    const ROOT_TRANSLATION = "navbar."
    const translate = (key) => t(`${ROOT_TRANSLATION}${key}`)

    const [isDrawerOpened, changeDrawerState] = useState(false)
    const [isMenuOpened, changeMenuState] = useState(null)
    const [isFlagsOpened, changeFlagsStatus] = useState(null)
    const [badge, setBadge] = useState({news: 0})
    const [page, setPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [favorites, setFavorites] = useState(false)
    const { userInfo } = usePdtContext()
    const dispatch = usePdtDispatch()

    useEffect(() => { 
        getCountNewNews().then(news => setBadge({news})) 
    }, [])

    useEffect(() => {
        setPage(props.routes.find(r => r.url === `${location.pathname}${location.hash}`)?.url ?? false)
    }, [ window.location.href ])

    const toggleDrawer = (open) => 
        (event) => {
            if (event.type === 'keydown' && event.key === 'Tab' || event.key === 'Shift')
                return

            changeDrawerState(open)
        }

    const handlePersonalMenu = (event) => {
        if(userInfo){
            changeMenuState(event?.currentTarget)
        }else{
            navigate('/login')
        }
    }

    const menuAction = (action) => {
        switch(action){
            case "search":
                setSearch(true)
                break
            case "favorites":
                setFavorites(true)
                break
            case "upload":
            case "manage":
                navigate(action)
                break
            case 'profile':
                navigate(`${action}/${userInfo.id}`)
                break
            case "logout": 
                logout().then(() =>
                    dispatch({type: "updateUser", user: undefined })
                )
                break
        }
        handlePersonalMenu(null)
    }

    const handleFlagsMenu = (event) => {
        changeFlagsStatus(event?.currentTarget)
    }

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang)
        changeFlagsStatus(null)
    }

    const getFlag = (lang) => {
        const language = lang ? lang : i18n.resolvedLanguage
        const Flag = availableLanguages.find(el => el.lang === language).flag

        return (
            <Flag width="30px" height="40px"/>
        )
    }
    
    return (
        <AppBar position="fixed" sx={{ justifyContent: 'center' }}>
            <Container maxWidth="xl" sx={{display:'flex', justifyContent:"space-between", alignContent: 'center', height:48}}>
                    <Box sx={{alignSelf: "center"}}>
                        <Logo sx={{ display: { xs: 'none', md: 'flex' }}} url={props.logo.url} label={props.logo.label} />
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <Badge component={IconButton} color="warning" overlap="circular" badgeContent=" " variant="dot" onClick={toggleDrawer(true)} invisible={!Object.values(badge).reduce((f,s) => f + s)}>
                                <MenuIcon  sx={{ color: t => t.palette.primary.contrastText }} />
                            </Badge>
                            <Drawer anchor="left" open={isDrawerOpened} onClose={toggleDrawer(false)} >
                                <Box sx={{ paddingY: 5, width: 250 }} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} >
                                    <Tabs value={page} orientation="vertical"
                                        textColor="primary" TabIndicatorProps={{ sx: { backgroundColor: t => t.palette.primary } }}>
                                        {props.routes.map((page,i) => (
                                            <Tab key={i} label={
                                                <Badge component={Box} key={i} color="success" badgeContent={badge[page.label]}
                                                    componentsProps={{ badge: { style: { right: '-6px' } } }}>
                                                        { translate(page.label) }
                                                </Badge>
                                            } value={page.url} sx={{ paddingX: 1 }}
                                            onClick={() => navigate(page.url, { state: page.state })} />
                                        ))}
                                    </Tabs>
                                </Box>
                            </Drawer>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',alignSelf:"center"}} >
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title={translate("lang")}>
                                <IconButton onClick={handleFlagsMenu}>
                                    { getFlag() }
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="menu-lang"
                                anchorEl={isFlagsOpened}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={Boolean(isFlagsOpened)}
                                onClose={() => handleFlagsMenu(null)} >
                                {
                                    availableLanguages.map(lang => (
                                        <MenuItem key={lang.lang} onClick={() => handleChangeLanguage(lang.lang)}>
                                            <ListItemIcon >
                                                { getFlag(lang.lang) }
                                            </ListItemIcon>
                                        </MenuItem>
                                    ))
                                }
                            </Menu>
                        </Box>
                        <Tooltip title={translate(userInfo ? "menu" : "login")} >
                            <IconButton onClick={handlePersonalMenu} sx={{ p: 0 }}>
                                { userInfo ?
                                    <Avatar alt={userInfo.sopranome ?? userInfo.nome} src={userInfo.imgAcc ? `/media/profilo/${userInfo.imgAcc}` : "avatar"} /> :
                                    <Login sx={{ color: "white", padding:'12px' }} />
                                }
                            </IconButton>
                        </Tooltip>
                        { userInfo && (<>
                            <Menu
                                id="menu-appbar"
                                anchorEl={isMenuOpened}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(isMenuOpened)}
                                onClose={() => handlePersonalMenu(null)}
                            >
                            { userInfo?.admin &&
                                <MenuItem key='manage' onClick={() => menuAction('manage')}>
                                    <Typography textAlign="center">
                                        Gestione sito
                                    </Typography>
                                </MenuItem>
                            }
                            {['profile', 'favorites', 'search', 'upload', 'logout'].map((setting) => (
                                <MenuItem key={setting} onClick={() => menuAction(setting)}>
                                    <Typography textAlign="center">
                                        {translate(setting)}
                                    </Typography>
                                </MenuItem>
                            ))}
                            </Menu>
                            { search && (
                                <CustomModal open={true} onClose={() => setSearch(false)}>
                                    <CercaUtente />
                                </CustomModal>
                            )}
                            { favorites && (
                                <CustomModal open={true} onClose={() => setFavorites(false)}>
                                    <Preferiti onClose={() => setFavorites(false)} />
                                </CustomModal>
                            )}
                        </>)}
                    </Box>
            </Container>
            <Box sx={{display:"flex", justifyContent:"center", position:"absolute", alignSelf: "center"}}>
                <Logo url={props.logo.url} label={props.logo.label}  sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
                <Tabs value={page} 
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                    textColor="inherit" TabIndicatorProps={{ sx: { backgroundColor: t => t.palette.primary.contrastText } }}>
                    {props.routes.map((page,i) => (
                        <Tab key={i} label={
                            <Badge component={Box} key={i} color="success" badgeContent={badge[page.label]}
                                componentsProps={{ badge: { style: { right: '-6px' } } }}>
                                    { translate(page.label) }
                            </Badge>
                        } value={page.url} sx={{ paddingX: 1, opacity: 1 }}
                        onClick={() => navigate(page.url, { state: page.state })} />
                    ))}
                </Tabs>
            </Box>
        </AppBar>
    )  
}

export default NavBar