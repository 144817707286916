import { SvgIcon } from "@mui/material";
import React from "react";

export default  props => 
        <SvgIcon {...props} viewBox="0 0 114 114" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(0.005781, 0, 0, -0.005781, -6342.733398, -4051.671387)" fill="#000000" stroke="none" style={{ transformOrigin: "6399.73px 4105.67px" }} >
            <path d="M6160 8205 c-1055 -131 -2120 -1076 -3247 -2885 -213 -342 -315 -519 -1193 -2085 -447 -797 -912 -1628 -1034 -1845 l-221 -394 -5 129 -5 130 -228 3 -227 2 0 -630 0 -630 230 0 230 0 0 128 0 128 1171 2045 c644 1124 1178 2051 1187 2060 9 9 638 400 1397 869 759 469 1490 923 1624 1008 361 228 393 242 557 242 158 0 192 -13 459 -180 77 -48 808 -500 1625 -1005 817 -505 1493 -925 1502 -934 9 -9 371 -635 804 -1391 433 -756 960 -1676 1171 -2044 l383 -670 0 -128 0 -128 230 0 230 0 0 635 0 635 -227 -2 -228 -3 -5 -135 -5 -134 -476 844 c-2019 3582 -1876 3333 -2224 3860 -405 614 -773 1078 -1172 1477 -776 774 -1553 1121 -2303 1028z"/>
            <path d="M3951 4680 c-523 -320 -819 -506 -827 -521 -10 -21 -257 -583 -844 -1929 -92 -212 -326 -746 -519 -1188 -193 -442 -351 -806 -351 -808 0 -2 2245 -4 4990 -4 2745 0 4990 2 4990 4 0 2 -158 366 -351 808 -193 442 -427 976 -519 1188 -92 212 -295 675 -450 1030 -155 355 -308 705 -340 779 -33 74 -63 137 -67 141 -9 9 -1621 1000 -1626 1000 -1 0 46 -465 104 -1032 249 -2408 231 -2220 219 -2290 -34 -186 -184 -345 -359 -378 -79 -15 -3112 -14 -3183 1 -136 29 -252 123 -315 255 -34 71 -38 88 -41 174 -2 69 39 515 152 1650 85 855 157 1570 160 1588 3 17 2 32 -2 31 -4 0 -373 -225 -821 -499z"/>
            </g>
            <g transform="matrix(1.00885, 0, 0, 1.00885, -0.999976, -0.999976)" style={{ transformOrigin: "58px 58px" }} >
            <ellipse style={{ paintOrder: "fill", fillRule: "evenodd", fill: "none", stroke: "rgb(255, 0, 0)", strokeWidth: "10px" }} cx="58" cy="58" rx="50" ry="50"/>
            <path style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(255, 0, 0)", strokeWidth: "10px", transformBox: "fill-box", transformOrigin: "50% 50%" }} d="M 8.546 58.276 L 108.546 58.816" transform="matrix(0.707108, 0.707106, -0.707106, 0.707108, -0.000002, -0.000011)"/>
            <ellipse style={{ stroke: "rgb(0, 0, 0)", fill: "none", strokeWidth: "0.5px" }} cx="58" cy="58" rx="56.5" ry="56.5"/>
            </g>
        </SvgIcon>