import { Box, Divider, List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { allPreferiti } from "../service"
import useNavigation from "../hook/navigation"
import { isModal, modalLink } from "../router";

export default ({ onClose }) => {
    const { t } = useTranslation()
    const navigate = useNavigation()
    const location = useLocation()

    const [baseLocation, setBaseLocation] = useState()
    const [pref, setPref] = useState()

    useEffect(() => {
        updateList()
        setBaseLocation(location)
    }, [])

    const sameLocation = () => location.pathname === baseLocation.pathname && location.search === baseLocation.search
    useEffect(() => {
        if(!baseLocation) return
        if(sameLocation()){
            updateList()
            return
        }
        if(!isModal(location.pathname) && !(location.pathname === "/boulder" && location.search.includes('bc'))){
            onClose()
        }
    },[location])

    const updateList = () => allPreferiti().then(pref => setPref(pref ?? []))

    return  (
        <List sx={{ width: '100%', minWidth: 300, maxWidth: 360, bgcolor: 'background.paper' }}
            subheader={<ListSubheader>{t('navbar.favorites').toUpperCase()}</ListSubheader>} >
                {(pref && !pref.length) && (
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemText disableTypography primary={
                            <Box  display='flex' sx={{ gap: 1, justifyContent: 'center' }}>
                                <Typography>{t('extra.noPref')}</Typography>
                            </Box>} />
                            </ListItemButton>
                    </ListItem>
                )}
                { pref && (
                    pref.map(p => (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {navigate(`/boulder?s=${p.idSettore}&bc=${p.idBlocco}`)}} >
                                <ListItemText disableTypography
                                    primary={<Box display='flex' sx={{ gap: 1 }}>
                                        <Typography>{p.nome}{p.sit ? ' (sit)' : ''} </Typography> 
                                        <Typography color={'text.disabled'}> {p.grado}</Typography>
                                        </Box>}
                                    secondary={<Box sx={{ display: 'flex', flexDirection: 'column'}}>
                                            <Typography component="span" variant="body2" color="text.primary" >
                                                { p.settore }
                                            </Typography>
                                            <Typography component="span" variant="caption">{p.commento}</Typography>
                                            
                                        </Box>} />
                            </ListItemButton>
                        </ListItem>
                    )).reduce((prev, act) => (<>
                        {prev}
                        <Divider variant="middle" component="li" />
                        {act}
                    </>), (<></>))
                )}
        </List>
    )
}