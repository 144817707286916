import React from 'react';
import { Container } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import IndexPage from './IndexPage.jsx';
import { ContextProvider } from "./context/AppContext";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <Container maxWidth={false} style={{ minWidth: 412, display:'flex', flexDirection:'column', padding:0, minHeight: '100vh'}}>
          <IndexPage />
        </Container>
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
