import React from "react"
import { Snackbar, IconButton, Slide } from "@mui/material";
import { Close } from '@mui/icons-material';
import { usePdtContext, usePdtDispatch } from "../context/AppContext"

export default () => {
    const pdtState = usePdtContext()
    const pdtDispatch = usePdtDispatch()

    const handleCloseSnackbar = () => {
        pdtDispatch({type: "snackbarClose"})
    }

    if (pdtState.snackbar?.open) {
        return (
            <Snackbar open
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                message={pdtState.snackbar?.text}
                TransitionComponent={Slide}
                TransitionProps={{ direction: "down" }}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                action={
                    <IconButton
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={handleCloseSnackbar}>
                        <Close />
                    </IconButton>
                } 
            />
        )   
    }

    return <></>
    
}