import { Card, CardContent, CardHeader, CardMedia, Container, Grid, Typography } from "@mui/material";
import { Person, LocationOn, Phone, Language, ShoppingCart, KeyboardReturn, Mail, Instagram } from '@mui/icons-material';
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import { forwardRef } from "react";

export default forwardRef((props, ref) => {

    const getTextDecoration = (boolean) => boolean ? 'underline' : 'unset'
    const Anchor = (props) => (<a href={props.href} target="_blank" style={{ color: "black", textDecoration: getTextDecoration(props.underline ?? true ) }} >{ props.children }</a> )

    const { t } = useTranslation()
    const ROOT_TRANSLATION = "homepage.who."
    const translate = (key, options) => {
        const transl = t(`${ROOT_TRANSLATION}${key}`, options)
        return transl !== `${ROOT_TRANSLATION}${key}` ? transl : undefined
    }

    const cardsContent = [ 'giacomo', 'rosario', 'rr', 'k2' ]

    const fontSize = 13
    const lineHeight = 2
    const printCards = () => cardsContent
        .map(key => (
            <Card key={translate(`cards.${key}.title`)} item component={Grid} xs={11} md={5}>
                <CardHeader sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}
                    title={translate(`cards.${key}.title`)}
                    subheader={translate(`cards.${key}.subtitle`)}
                    titleTypographyProps={{ fontSize:15, letterSpacing:2, fontWeight:600, align:'center' }}
                    subheaderTypographyProps={{ fontSize, align:'center', height:40 }}
                />
                <Container sx={{ display: 'flex', padding:'0px !important' }}>
                    <CardMedia
                        component="img"
                        sx={{ maxWidth:200, maxHeight:160 }}
                        image={translate(`cards.${key}.media`)}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0', padding: 'unset !important', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            { translate(`cards.${key}.name`) ? (<Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} ><Person/>{ translate(`cards.${key}.name`) }</Typography>) : <></> }
                            { translate(`cards.${key}.street`) ? (<Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} >
                                <LocationOn/>
                                <Anchor href={translate(`cards.${key}.gps`)}>{translate(`cards.${key}.street`)}</Anchor>
                            </Typography>) : <></> }
                            { translate(`cards.${key}.phone`) ? (<Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} >
                                <Phone/>
                                <Anchor href={`tel:${ translate(`cards.${key}.phone`) }`} underline={false}>{ translate(`cards.${key}.phone`) }</Anchor>
                                </Typography>) : <></> }
                            { translate(`cards.${key}.website`) ? (<Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} >
                                <Language/>
                                <Anchor href={translate(`cards.${key}.website`)}>Website</Anchor>
                            </Typography>) : <></> }
                            { translate(`cards.${key}.webshop`) ? (<Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize}>
                                <ShoppingCart/>
                                <Anchor href={translate(`cards.${key}.webshop`)}>Webshop</Anchor>
                            </Typography>) : <></> }
                        </CardContent>
                    </Box>
                </Container>
            </Card>
        )
    )

    return (
    <Container ref={ref} style={{ padding: "55px 0px 55px 0px" }}>
        <Container style={{ display:'flex', flexDirection:'column', alignItems:'center' }} >
            <Typography fontSize={30} letterSpacing={4}>{ translate('title') }</Typography>
        </Container>
        <Container sx={{ paddingX: { xs: 0, md: 3 }}}>
            <Grid container gap={4} justifyContent="center">
                <Card item component={Grid} xs={11} md={11}>
                    <CardHeader sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}
                        title="Pietra del Toro"
                        subheader={ translate('subtitle') }
                        titleTypographyProps={{ fontSize:15, letterSpacing:2, fontWeight:600, align:'center' }}
                        subheaderTypographyProps={{ fontSize, align:'center', height:40 }}
                    />
                    <Container sx={{ display: 'flex', padding:'0px !important', justifyContent:"space-around", alignItems: 'center' }}>
                        <CardMedia component="img"
                            sx={{ maxWidth:200, maxHeight:200 }}
                            image="assets/watermark.png"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0', padding: 'unset', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} ><Person/>Michele Urbano</Typography>
                                <Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} paddingBottom={2}>
                                    <KeyboardReturn style={{ transform: 'rotateY(180deg)', paddingRight:8}}/><Phone/>
                                    <Anchor href={ `tel:+393294651592` } underline={false}>+39 329 465 1592</Anchor>
                                </Typography>
                                <Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} ><Person/>Graziano Montel</Typography>
                                <Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} paddingBottom={2}>
                                    <KeyboardReturn style={{ transform: 'rotateY(180deg)', paddingRight:8}}/><Phone/>
                                    <Anchor href={ `tel:+393296163220` } underline={false}>+39 329 61 63 220</Anchor>
                                </Typography>
                                <Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} >
                                    <Instagram/>
                                    <Anchor href={ `https://www.instagram.com/pietradeltoro` }>Instagram</Anchor>
                                </Typography>
                                <Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} >
                                    <Mail/>
                                    <Anchor href={ `mailto:info@pietradeltoro.com` }>info@pietradeltoro.com</Anchor>
                                </Typography>
                                <Typography component="div" display="flex" lineHeight={lineHeight} fontSize={fontSize} >
                                    <LocationOn/>
                                    <Link to={{ pathname: "/", hash: "#map"}} state={{ clicked: true }}>{ translate('parcheggio') }</Link>
                                </Typography>
                            </CardContent>
                        </Box>
                    </Container>
                </Card>
                { printCards() }
            </Grid>
        </Container>
    </Container>
    )
})