import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18nConfig'

String.prototype.format = function() {
    let a = this;
    for (let k in arguments) {
      a = a.replace("{" + k + "}", arguments[k])
    }
    return a
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);